import React from "react";
import Anime from "react-anime";
import VisibilitySensor from "react-visibility-sensor";
const Cell = props => {
  const defaultSettings = {
    easing: "linear",
    duration: 60000,
    direction: "normal",
    loop: false,
    rotate: 0.01,
    // translateZ: 0
  };

  const overwriteSettings = {
    // duration: 800,
    // loop: true,
  };




  const [active, setActive] = React.useState(false);

  const settingsCell = { ...defaultSettings, ...props.settings,...overwriteSettings };
  function onVisible(isVisible) {
    // console.log("Element is now %s", isVisible ? "visible" : "hidden");
    setActive(isVisible ? true : false);
  }

  return (
    <VisibilitySensor onChange={onVisible} partialVisibility={true}>
      <div className={`cell ${props.className}`} style={props.style}>
        <Anime {...settingsCell} autoplay={active}>
          <div className="cell__inner">{props.children}</div>
        </Anime>
      </div>
    </VisibilitySensor>
  );
};

export default Cell;
