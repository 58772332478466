import React from "react";
import { Link } from "gatsby";
import Layout from "../layout/base";
import SEO from "../components/seo";

import Stage from "../components/stage";
import Cell from "../components/cell";
import MorphingBubble from "../components/morphingbubble";
import JSON from "../../data/index.json";

const IndexPage = ({ i18n = "en" }) => (
  <Layout site="home" footer="" i18n={i18n} url="/">
    <SEO
      title={JSON[i18n].meta.title}
      description={JSON[i18n].meta.description}
      lang={i18n}
    />
    <Stage className="" site="home" i18n={i18n}>
      <div className="stage__title ">
        <h1 dangerouslySetInnerHTML={{ __html: JSON[i18n].stage.title }}></h1>
      </div>
    </Stage>

    <div className="container ">
      <div className="d-flex_ row flex-column-reverse flex-lg-row">
        <div className="col-12 col-lg-6 position-relative d-flex align-items-center">
          <div className="util-zindex position-relative  text-lg-left">
            <h2 className="util-h1">{JSON[i18n].box1.title}</h2>
            <p
              className="util-text-1"
              dangerouslySetInnerHTML={{ __html: JSON[i18n].box1.text }}
            ></p>
            <Link
              to={(i18n !== "en" ? "/" + i18n : "") + "/clinical-trial"}
              className="btn btn--primary btn--bg-gray mt-4"
            >
              {JSON[i18n].box1.button}
            </Link>
          </div>

          <Cell
            className="d-none d-xxl-block"
            style={{
              width: "300px",
              top: "0%",
              left: "-80%",
            }}
          >
            <img
              className="lazyload"
              data-src={require("../images/elements/green-bubble-1.svg")}
              alt="Green Bubble 1"
            />
          </Cell>
          <Cell
            className="d-none d-xl-block d-xxl-none"
            style={{
              width: "300px",
              top: "0%",
              left: "-75%",
            }}
          >
            <img
              className="lazyload"
              data-src={require("../images/elements/green-bubble-1.svg")}
              alt="Green Bubble 1"
            />
          </Cell>
        </div>
        <div className="col-12 col-lg-6 mb-4 mb-sm-0">
          <MorphingBubble id="version7" />
        </div>
      </div>
    </div>

    <div className="container mb-5">
      <div className="row position-relative">
        <div className="col-12 col-lg-6">
          <MorphingBubble id="version2" />
        </div>
        <div className="col-12 col-lg-6 d-flex align-self-center position-relative pr-lg-5">
          <div className="util-zindex position-relative">
            <h2 className="util-h1">{JSON[i18n].box2.title}</h2>
            <p
              className="util-text-1 pr-lg-5"
              dangerouslySetInnerHTML={{ __html: JSON[i18n].box2.text }}
            ></p>
            <Link
              to={(i18n !== "en" ? "/" + i18n : "") + "/car-t-cell-therapy"}
              className="btn btn--primary btn--bg-gray mt-4"
            >
              {JSON[i18n].box2.button}
            </Link>
          </div>
          <Cell
            className="d-none d-xl-block d-xxl-none"
            style={{
              width: "300px",
              top: "-50%",
              right: "-40%",
            }}
          >
            <img
              className="lazyload"
              data-src={require("../images/elements/green-bubble-2.svg")}
              alt="Green Bubble 2"
            />
          </Cell>
          <Cell
            className="d-none d-xxl-block"
            style={{
              width: "300px",
              top: "-50%",
              right: "-70%",
            }}
          >
            <img
              className="lazyload"
              data-src={require("../images/elements/green-bubble-2.svg")}
              alt="Green Bubble 2"
            />
          </Cell>
        </div>
      </div>
    </div>

    <div className="container util-content-mt-1 util-content-mb-2">
      <div className="d-flex_ row flex-column-reverse flex-lg-row">
        <div className="col-12 col-lg-6 position-relative  d-flex align-self-center">
          <div className="util-zindex position-relative  text-lg-left">
            <h2 className="util-h1">{JSON[i18n].box3.title}</h2>
            <p
              className="util-text-1"
              dangerouslySetInnerHTML={{ __html: JSON[i18n].box3.text }}
            ></p>
            <Link
              target="_blank"
              to="https://www.miltenyibiomedicine.com/"
              className="btn btn--primary btn--bg-gray mt-4"
            >
              {JSON[i18n].box3.button}
            </Link>
          </div>

          <Cell
            className="d-none d-xxl-block"
            style={{
              width: "300px",
              top: "0%",
              left: "-80%",
            }}
          >
            <img
              className="lazyload"
              data-src={require("../images/elements/green-bubble-1.svg")}
              alt="Green Bubble 1"
            />
          </Cell>
          <Cell
            className="d-none d-xl-block d-xxl-none"
            style={{
              width: "300px",
              top: "0%",
              left: "-75%",
            }}
          >
            <img
              className="lazyload"
              data-src={require("../images/elements/green-bubble-1.svg")}
              alt="Green Bubble 1"
            />
          </Cell>
        </div>
        <div className="col-12 col-lg-6 mb-4 mb-sm-0">
          <MorphingBubble id="version1" />
        </div>
      </div>
    </div>
   
  </Layout>
);

export default IndexPage;
